import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "modal", "input", "name", "errors", "send" ]

  showModal() {
    this.modalTarget.classList.add('is-active')
  }

  closeModal() {
    this.modalTarget.classList.remove('is-active')
  }

  checkValidation() {
    const errors = this.checkTag(this.inputTarget.value, [])
    this.errorsTarget.textContent = errors.join('\n')
    if(errors > 0) {
      this.sendTarget.disabled = true
    }else{
      this.sendTarget.disabled = false
    }
  }

  checkTag(value, errors = []) {
    return [
      ...this.tagMustBeFull(value, errors),
      ...this.tagMustBeUniq(value, errors),
      ...this.tagMustBeValidFormat(value, errors)
    ]
  }

  tagMustBeFull(value, errors = []) {
    let errs = []
    Object.assign(errs, errors)
    if(value === '') {
      errs.push('ハッシュタグを入力してください。')
    }
    return errs
  }

  tagMustBeUniq(value, errors = []) {
    let errs = []
    Object.assign(errs, errors)
    const names = this.nameTargets.map((item) => item.textContent)
    if(names.includes(value)) {
      errs.push('同じハッシュタグが含まれています。')
    }
    return errs
  }

  tagMustBeValidFormat(value, errors = []) {
    let errs = []
    Object.assign(errs, errors)

    if (value && /\s/.test(value)) { errs.push('半角スペースが含まれています。'); }
    if (value && /　/.test(value)) { errs.push('全角スペースが含まれています。'); }
    if (value && /＃/.test(value)) { errs.push('全角＃が含まれています。'); }
    if (value && /\$/.test(value)) { errs.push('$が含まれています。'); }
    if (value && /%/.test(value)) { errs.push('%が含まれています。'); }

    return errs
  }
}
